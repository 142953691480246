<template>
  <div>
    <div class="h-1 bg-sign-darkblue"></div>
    <!-- header -->
    <header class="py-2">
      <div class="container">
        <div class="flex items-center justify-between py-3">
          <div class="md:w-32">
            <img src="/images/logo.png" alt="" class="mx-auto" />
          </div>
          <div class="space-y-2 md:flex md:space-y-0 md:space-x-3">
            <div>
              <div class="inline-flex items-center">
                <img src="/images/whatsapp.png" alt="" class="w-10 md:w-14">
                <p
                  class="pl-2 text-lg font-semibold leading-tight transition duration-200 ease-linear text-sign-darkblue hover:text-sign-orange">
                  <a href="https://wa.me/6594886428">WhatsApp Us <br> 65 9488 6428</a>
                </p>
              </div>
            </div>
            <div>
              <div class="inline-flex items-center">
                <img src="/images/call.png" alt="" class="w-10 md:w-14">
                <p
                  class="pl-2 text-lg font-semibold leading-tight transition duration-200 ease-linear text-sign-darkblue hover:text-sign-orange">
                  <a href="tel:+6566352882">Call Us <br> 65 6635 2882</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {};
</script>

<style></style>