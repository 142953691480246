<template>
  <div>
    <WhatsappButton />
    <MainHeader />
    <!-- main banner -->
    <MainBanner />

    <!-- about us -->
    <div class="py-10 bg-sign-liteblue">
      <div class="container">
        <h1 class="pb-5 text-xl font-bold text-center md:text-2xl text-sign-darkblue">
          Who Are <span class="text-sign-orange">We?</span>
        </h1>
        <div class="w-10 h-1 mx-auto bg-sign-darkblue"></div>
        <div class="lg:flex lg:items-center">
          <div class="pt-6 lg:w-1/2 lg:order-2">
            <p class="text-sm text-gray-700">
              <span class="font-bold text-center text-sign-orange">Acrylic Signs Material Pte Ltd</span>
              was established over 30 years ago. Today, Acrylic Signs Material Pte Ltd had diversified into
              many other design materials such as <span class="font-bold text-sign-orange">Banner, Vinyl Stickers, PC
                Sheets, PVC, PE Tarpaulin and etc</span>. In order to strive for excellent
              quality and competitive pricing in the market, the company has
              participated in various international exhibition and trade
              convention, and today, we import directly from various countries
              such as Korea, Japan, Taiwan, China and etc.
            </p>
          </div>
          <div class="lg:w-1/2">
            <img src="/images/about-us.png" alt="" class="mx-auto lg:w-4/5" />
          </div>
        </div>
      </div>
    </div>

    <!-- Our Services -->
    <div class="py-10">
      <div class="container">
        <h1 class="pb-5 text-xl font-bold text-center md:text-2xl text-sign-darkblue">
          Our <span class="text-sign-orange">Services</span>
        </h1>
        <div class="w-10 h-1 mx-auto bg-sign-darkblue"></div>
        <p class="pt-3 text-center text-gray-700">
          Manufacturing, Wholesaler & Supplier
        </p>

        <div class="flex flex-wrap pt-4">
          <div v-for="(item, i) in services" :key="i" class="w-1/2 p-1 shadow-lg md:w-1/4">
            <img :src="item.image" :alt="item.alt" class="" />
            <div
              class="p-4 text-gray-700 transition duration-300 ease-linear bg-white hover:bg-sign-orange hover:text-white">
              <p class="text-sm leading-tight text-center h-14">
                {{ item.alt }}
              </p>
            </div>
          </div>
        </div>
        <div class="pt-5">
          <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }">
            <div class="w-40 mx-auto rounded-lg bg-sign-darkblue">
              <h1 class="py-2 text-center text-white">Enquire Us Now</h1>
            </div>
          </a>
        </div>
      </div>
    </div>

    <!-- 2nd taglink -->
    <div class="py-10 bg-sign-darkblue">
      <div class="container">
        <h1 class="pb-3 text-3xl font-bold text-center text-sign-orange">
          Make Acrylic Signs Material Pte Ltd <br class="hidden md:block"> Be Your Choice
        </h1>
        <div class="w-10 h-1 mx-auto bg-white"></div>
        <p class="pt-5 text-sm text-center text-white md:w-3/4 md:mx-auto">
          We are dedicated to make each customer’s needs and satisfaction a
          priority. With our passionate and professional workers, we provide you
          the quality acrylic products & services.
        </p>
      </div>
    </div>

    <!-- specialize -->
    <div class="py-10">
      <div class="container">
        <h1 class="pb-5 text-xl font-bold text-center md:text-2xl text-sign-darkblue">
          We <span class="text-sign-orange">Specialize In</span>
        </h1>
        <div class="w-10 h-1 mx-auto bg-sign-darkblue"></div>

        <div class="flex flex-wrap pt-5">
          <div v-for="(item, i) in special" :key="i" class="w-1/2 p-1 pb-2 md:w-1/4">
            <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
            <h1 class="pt-2 text-sm font-medium leading-tight text-center text-sign-darkblue">
              {{ item.alt }}
            </h1>
          </div>
        </div>
        <div class="pt-5">
          <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }">
            <div class="w-40 mx-auto rounded-lg bg-sign-darkblue">
              <h1 class="py-2 text-center text-white">Enquire Us Now</h1>
            </div>
          </a>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div class="py-10 bg-sign-liteblue">
      <div class="container">
        <h1 class="pb-5 text-xl font-bold text-center md:text-2xl text-sign-darkblue">
          Why <span class="text-sign-orange">Choose Us</span>
        </h1>
        <div class="w-10 h-1 mx-auto bg-sign-darkblue"></div>
        <p class="pt-4 text-sm font-bold text-center text-gray-700">
          <span class="text-lg font-bold leading-tight">The #1 Acrylic Solution Provider In Singapore</span> <br />
          Your satisfaction is our first priority. That’s why Acrylic Signs
          Material Pte Ltd is the perfect choice for you.
        </p>

        <div class="flex flex-wrap pt-6 lg:max-w-3xl lg:mx-auto">
          <div v-for="(item, i) in choose" :key="i" class="w-1/2 p-2 md:w-1/4">
            <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
            <h1 class="pt-3 text-base font-bold leading-tight text-center md:text-sm text-sign-darkblue">
              {{ item.alt }}
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- Our Works section -->
    <div class="py-10">
      <div class="container">
        <h1 class="pb-5 text-xl font-bold text-center md:text-2xl text-sign-darkblue">
          Our <span class="text-sign-orange">Clienteles</span>
        </h1>
        <div class="w-10 h-1 mx-auto bg-sign-darkblue"></div>
        <!-- works section -->
        <div id="works" class="pt-5 lg:py-6">
          <carousel :loop="true" :autoplay="true" :dots="false" :autoplaySpeed="3000" :autoplayTimeout="3000" :responsive="{
            0: { items: 2, nav: false },
            700: { items: 4, nav: false },
            1000: { items: 5, nav: false },
          }">
            <!--carousel area-->
            <div v-for="(item, i) in client" :key="i" class="px-1">
              <img :src="item.image" :alt="item.alt" class="rounded-sm" />
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- Our Latest Work -->
    <div class="py-10">
      <div class="container">
        <h1 class="pb-5 text-xl font-bold text-center md:text-2xl text-sign-darkblue">
          Our <span class="text-sign-orange">Latest Works</span>
        </h1>
        <div class="w-10 h-1 mx-auto bg-sign-darkblue"></div>

        <div class="pt-5 md:flex md:flex-wrap md:justify-center">
          <div v-for="(item, i) in works" :key="i" class="pb-1 md:p-1 md:w-1/3 lg:w-1/4">
            <img :src="item.image" :alt="item.alt" class="mx-auto" />
          </div>
        </div>
      </div>
    </div>

    <!-- enquiry form -->
    <div id="element" class="py-10 bg-sign-orange">
      <div class="container">
        <h1 class="pb-5 text-xl font-bold text-center text-white md:text-2xl">
          Send An Enquiry
        </h1>
        <div class="w-10 h-1 mx-auto bg-white"></div>
        <p class="pt-3 text-sm leading-tight text-center text-white">
          Fill out the form below and we'll get back to you as soon as possible
        </p>
        <div class="pt-5 lg:w-2/3 lg:mx-auto">
          <!-- feedback.activamedia.com.sg script begins here --><iframe allowTransparency="true"
            style="min-height: 480px; height: inherit; overflow: auto" width="100%" id="contactform123"
            name="contactform123" marginwidth="0" marginheight="0" frameborder="0"
            src="https://feedback.activamedia.com.sg/my-contact-form-3440165.html">
            <p>
              Your browser does not support iframes. The contact form cannot be
              displayed. Please use another contact method (phone, fax etc)
            </p>
          </iframe><!-- feedback.activamedia.com.sg script ends here -->
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="py-10">
      <div class="container">
        <div class="lg:flex lg:items-center">
          <div class="lg:w-1/2">
            <img src="/images/logo.png" alt="" class="w-32 mx-auto" />
            <h1 class="text-lg font-bold text-center text-sign-darkblue">
              Acrylic Signs Material Pte Ltd
              <br>
              Address: Block 3027 Ubi Road 1 #01-138, Singapore 408720
            </h1>
            <div class="mt-3 text-lg font-medium text-center text-sign-darkblue">
              <p><i class="pr-1 text-lg font-semibold fa-brands fa-whatsapp"></i> <a href="https://wa.me/6594886428"
                  class="hover:text-sign-orange">+65 9488 6428 </a> | <a href="https://wa.me/6590093648"
                  class="hover:text-sign-orange">+65 9009 3648</a></p>
              <p><i class="pr-1 text-lg font-semibold fa-solid fa-phone"></i><a href="tel:+6566352882"
                  class="hover:text-sign-orange">+65 6635 2882</a></p>
            </div>
          </div>
          <div class="pt-4 lg:w-1/2 lg:pt-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.064286509705!2d103.887789!3d1.315921!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x18167ff804b4206!2sAcrylic%20Signs%20Material%20Pte.%20Ltd.!5e0!3m2!1sen!2smy!4v1629190809068!5m2!1sen!2smy"
              width="100%" height="200" style="border: 0" allowfullscreen="" loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </div>
    <Copyright />
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from "vue-owl-carousel";
import MainHeader from "@/components/MainHeader.vue"
import MainBanner from "@/components/MainBanner.vue"
import Copyright from "@/components/Copyright.vue"
import WhatsappButton from "@/components/WhatsappButton.vue"

export default {
  name: "Home",
  components: {
    carousel,
    MainHeader,
    MainBanner,
    Copyright,
    WhatsappButton
  },
  data() {
    return {
      services: [
        {
          image: "/images/product-1.jpg",
          alt: "Acrylic Sheets / Rods / Tubes / Mirrors",
        },
        {
          image: "/images/product-2.jpg",
          alt: "Polycarbonate Sheets / Film / Twin Wall",
        },
        {
          image: "/images/product-3.jpg",
          alt: "PVC Sheet / Foam / Film / Vinyl Sticker",
        },
        { image: "/images/product-4.jpg", alt: "Banner Laminates / Flex" },
        {
          image: "/images/product-5.jpg",
          alt: "ACS Aluminium Directional Sign System",
        },
        { image: "/images/product-6.jpg", alt: "Reflective / Florescent Viny" },
        {
          image: "/images/product-7.jpg",
          alt: "Double Side Tape / Masking Tape",
        },
        {
          image: "/images/product-8.jpg",
          alt: "All Kinds of Advertising Display System & Materials",
        },
      ],
      special: [
        {
          image: "/images/special-1.png",
          alt: "Inkjet Images Service On Flexible And Rigid Material",
        },
        {
          image: "/images/special-2.png",
          alt: "Laser Cutting / Engraving Service On Non-Metal Material",
        },
        {
          image: "/images/special-3.png",
          alt: "Acrylic Tray / Box / Dispenser",
        },
        { image: "/images/special-4.png", alt: "Directional Sign System" },
        { image: "/images/special-5.png", alt: "Indoor / Outdoor Sign" },
        {
          image: "/images/special-6.png",
          alt: "Bunting / Banner / Billboards / Poster",
        },
        { image: "/images/special-7.png", alt: "Rounting / Engraving Service" },
        { image: "/images/special-8.png", alt: "Custom-Made Products" },
      ],
      choose: [
        { image: "/images/icon-1.png", alt: "Quality Assurance" },
        { image: "/images/icon-2.png", alt: "99% Customer Satisfaction" },
        { image: "/images/icon-3.png", alt: "Professional" },
        { image: "/images/icon-4.png", alt: "Excellent Services" },
      ],
      client: [
        { image: "/images/client-1.jpg", alt: "" },
        { image: "/images/client-2.jpg", alt: "" },
        { image: "/images/client-3.jpg", alt: "" },
        { image: "/images/client-4.jpg", alt: "" },
        { image: "/images/client-5.jpg", alt: "" },
        { image: "/images/client-6.jpg", alt: "" },
        { image: "/images/client-7.jpg", alt: "" },
        { image: "/images/client-8.jpg", alt: "" },
      ],
      works: [
        { image: "/images/products-1.jpg", alt: "" },
        { image: "/images/products-2.jpg", alt: "" },
        { image: "/images/products-3.jpg", alt: "" },
        { image: "/images/products-4.jpg", alt: "" },
        { image: "/images/products-5.jpg", alt: "" },
        { image: "/images/products-6.jpg", alt: "" },
        { image: "/images/products-7.jpg", alt: "" },
        { image: "/images/products-8.jpg", alt: "" },
      ],
    };
  },
};
</script>

<style>
#works .owl-carousel .owl-stage {
  transition-timing-function: linear !important;
}
</style>
