<template>
  <div class="bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-main.jpg')">
    <div class="container py-10">
      <div class="lg:relative md:flex md:items-center">
        <div class="md:w-1/2 md:order-2 lg:ml-auto">
          <img src="/images/bg-product.png" alt="" class="md:mx-auto lg:w-3/4" />
        </div>
        <div
          class="pt-3 text-3xl font-bold leading-tight text-center lg:absolute lg:text-6xl lg:w-2/3 md:text-5xl md:w-1/2 md:text-left md:order-1">
          <h1 class="text-sign-darkblue">Your One-Stop</h1>
          <h1 class="text-sign-orange">Acrylic Solution Provider</h1>
          <div class="mt-3 text-lg lg:text-xl text-sign-darkblue">
            <p><i class="pr-1 text-lg font-semibold fa-brands fa-whatsapp"></i> <a href="https://wa.me/6594886428"
                class="hover:text-sign-orange">+65 9488 6428 </a> | <a href="https://wa.me/6590093648"
                class="hover:text-sign-orange">+65 9009 3648</a></p>
            <p><i class="pr-1 text-lg font-semibold fa-solid fa-phone"></i><a href="tel:+6566352882" class="hover:text-sign-orange">+65 6635 2882</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>