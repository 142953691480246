<template>
  <div>
    <div
      id="wa-btn"
      class="fixed bottom-0 right-0 z-30 flex items-center justify-center w-12 h-12 mb-5 mr-4 rounded-full cursor-pointer"
    >
      <button @click="drawer">
        <svg
          class="w-10 h-10 text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 32 32"
        >
          <path
            d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
            fill-rule="evenodd"
          />
        </svg>
      </button>
    </div>

    <!-- aside -->
    <div
      class="fixed z-40 h-auto transition-all duration-300 ease-in-out transform bg-white rounded-md shadow-md w-72 lg:w-96 bottom-20 right-4"
      :class="isOpen ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-96'"
    >
      <div class="px-3 py-5 bg-green-500 rounded-t-md">
        <div class="flex justify-center text-white">
          <i class="text-4xl fa-brands fa-whatsapp"></i>
          <div class="pl-4">
            <p class="font-semibold">Start a Conversation</p>
            <p class="pt-2 text-xs text-gray-100">
              Hi! Click one of our member below to chat on WhatsApp
            </p>
          </div>
        </div>
      </div>
      <div class="px-4 py-4">
        <p class="text-xs text-gray-600">
          The team typically replies in a few minutes.
        </p>
        <div v-for="(item, i) in whatsapp" :key="i"
          class="flex items-start p-3 mt-3 bg-gray-100 border-l-2 border-green-1"
        >
          <WhatsappIcon />
          <a :href="'https://wa.me/' + item.link">
            <div class="pl-2">
              <p class="font-semibold">{{ item.p }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- end aside -->
  </div>
</template>
<script>
import WhatsappIcon from '@/components/WhatsappIcon.vue';

export default{
  components:{
    WhatsappIcon
  },
  data() {
    return {
      isOpen: false,
      whatsapp:[
        { link:'6594886428', p:'+65-94886428',},
        { link:'6590093648', p:'+65-90093648',},
      ]
    };
  },
  methods: {
    drawer() {
      this.isOpen = !this.isOpen;
    },
  },
  // watch: {
  //   isOpen: {
  //     immediate: true,
  //     handler(isOpen) {
  //       if (process.client) {
  //         if (isOpen) document.body.style.setProperty("overflow", "hidden");
  //         else document.body.style.removeProperty("overflow");
  //       }
  //     },
  //   },
  // },
  // mounted() {
  //   document.addEventListener("keydown", (e) => {
  //     if (e.keyCode == 27 && this.isOpen) this.isOpen = false;
  //   });
  // },
}

</script>

<style scoped>
#wa-btn {
  background-color: #4dc247;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
}
#wa-btn:hover {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.3);
}
</style>
